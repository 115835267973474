/* fichier JS de base commun quelques soient les versions du projet web */

// import 'bootstrap'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'

// lazy loading => https://github.com/aFarkas/lazysizes
// import lazySizes from 'lazysizes'
import 'lazysizes/plugins/aspectratio/ls.aspectratio'
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// bootstrap => customisez bootstrap directement depuis un fichier custom et sélectionnez les modules communs utilisés sur toutes les pages du site au lieu de tout importer
import './scss/bootstrap'
// global styles
import './scss/custom'

// Scripts librairies
import { handle, sticky } from './js/libs/misc'
import './js/libs/utils'

// lazySizes.cfg.expand = 80
// lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', (e) => {
  const bg = e.target.getAttribute('data-background')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Scroll to top
async function getScrollToTop() {
  import(/* webpackChunkName: "scrollToTop" */ './js/components/scrollToTop.js')
}

// Swiper Carousel
async function getSwiperCarousel() {
  import(/* webpackChunkName: "swiperCarousel" */ './js/components/swiperCarousel.js').then(() => {
    Waypoint.refreshAll()
  })
}

// Agencies Search Form
async function getAsForm($f) {
  $f.load()
  import(/* webpackChunkName: "agenciesSearchForm" */ './js/components/agenciesSearchForm.js').then(() => {
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Agency autocomplete
async function getAgenciesAutocomplete($f) {
  import(/* webpackChunkName: "agenciesAutocomplete" */ './js/components/agenciesAutocomplete.js').then((module) => {
    module.agenciesAutocomplete.init($f)
  })
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  // sur le scroll
  const w = $(window)
  w.scroll(function () {
    if (w.scrollTop() >= 130 && !$('#scrollUp').length) {
      getScrollToTop()
    }
    sticky()
  })

  // boutons liens
  $('button[data-toggle="link"]').on('click', function () {
    const link = $(this).attr('data-href')
    if ($(this).attr('data-target') === 'self') {
      document.location.href = link
    } else {
      window.open(link)
    }
  })

  // ancre
  $('.scroll').each(function () {
    const me = $(this)
    me.on('click', function (e) {
      const t = $(e.target).is('a') ? this.hash : $(this).attr('target')
      e.preventDefault()
      if ($(t).length > 0) {
        $('html').animate(
          { scrollTop: $(t).offset().top - $('#header').height() - 20 },
          { duration: 200, easing: 'linear' },
          function () {
            $('html').animate(
              { scrollTop: $(t).offset().top - $('#header').height() - 20 },
              { duration: 200, easing: 'linear' }
            )
          }
        )
      }
    })
  })

  // gestion des carousel
  const $s = $('.swiper-carousel')
  if ($s.length) {
    $s.waypoint(
      function () {
        getSwiperCarousel()
        this.destroy()
      },
      {
        offset: '100%',
      }
    )
  }

  // gestion des service box items
  const $sbi = $('.sb-item')
  if ($sbi.length) {
    $sbi.waypoint(
      function () {
        import(/* webpackChunkName: "serviceBoxItem" */ './scss/components/serviceBoxItem.scss').then(() => {
          Waypoint.refreshAll()
        })
        this.destroy()
      },
      {
        offset: '100%',
      }
    )
  }

  // gestion de la recherche d'agences la plus proche
  const $c1 = $('form[name="asForm"]')
  if ($c1.length) {
    $c1.waypoint(
      function () {
        getAsForm($c1)
        this.destroy()
      },
      {
        offset: '100%',
      }
    )
  }

  // filtres des agences autocomplete
  $('form[name="asForm"] input[name="location"]').on('click keyup focus', function () {
    $(this).blur().off().attr('disabled', '').val('Veuillez patienter...')
    getAgenciesAutocomplete($(this).parents('form'))
  })

  // handle sur toutes les pages du site
  handle()
  sticky()
})
